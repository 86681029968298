<template>
  <div
    class="flex flex-col w-full border border-secondary-250 rounded-4 bg-white">
    <div
      v-if="hasSlot('title') || title"
      :class="getDensityClass"
      class="border-b border-secondary-250 py-2.5 flex flex-col">
      <slot name="title">
        <h6 class="title_xs">{{ title }}</h6>
      </slot>
    </div>

    <section
      :class="getDensityClass"
      class="flex flex-1 justify-center flex-col">
      <slot />
    </section>
    <div
      v-if="hasSlot('footer')"
      :class="getDensityClass"
      class="flex flex-col justify-center border-t border-secondary-250 py-2 px-5">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, useSlots, computed } from 'vue';
import classNames from 'classnames';

type DensityType = 'lg' | 'md' | 'sm' | 'none';
const densityClasses: Record<DensityType, string> = {
  lg: 'p-10',
  md: 'p-5',
  sm: 'p-2.5',
  none: 'p-0',
};

const slots = useSlots();
const hasSlot = name => !!slots[name];

const props = defineProps({
  density: {
    type: String as PropType<DensityType>,
    default: 'sm',
  },
  title: {
    type: String,
    default: null,
  },
});

const getDensityClass = computed(() =>
  classNames(densityClasses[props.density]),
);
</script>
